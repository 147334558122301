<template>
  <div id="app">
    <!-- page wrapper start -->
    <div class="page-wrapper">

      <!--header end-->

      <!--hero section start-->
      <HeroSection />
      <!--hero section end-->

      <!--body content start-->

      <PageContent />

      <!--pricing start-->
      <Pricing />
      <!--pricing end-->

      <!--faq start-->
      <Faq />
      <!--faq end-->

      <!--footer start-->
     
      <!--body content end-->
    </div>
  </div>
</template>

<script>

import HeroSection from "../components/heroSection/HeroSection.vue";
import PageContent from "../components/pageContent/PageContent.vue";
import Pricing from "../components/pricing/Pricing.vue";
import Faq from "../components/faq/faq.vue";




export default {
  name: "HomeView",
  components: {
    HeroSection,
    PageContent,
    Pricing,
    Faq,
},
};
</script>
