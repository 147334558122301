var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"py-11 bg-primary position-relative",attrs:{"id":"contactenos","data-bg-img":"images/bg/03.png"}},[_c('div',{staticClass:"shape-1",staticStyle:{"height":"150px","overflow":"hidden"}},[_c('svg',{staticStyle:{"height":"100%","width":"100%"},attrs:{"viewBox":"0 0 500 150","preserveAspectRatio":"none"}},[_c('path',{staticStyle:{"stroke":"none","fill":"#fff"},attrs:{"d":"M0.00,49.98 C150.00,150.00 271.49,-50.00 500.00,49.98 L500.00,0.00 L0.00,0.00 Z"}})])]),_c('div',{staticClass:"container mt-7"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg-5 col-xl-4 me-auto mb-6 mb-lg-0"},[_c('FormContact')],1),_c('div',{staticClass:"col-12 col-lg-6 col-xl-7"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-4 navbar-dark"},[_c('h5',{staticClass:"mb-4 text-white"},[_vm._v("Paginas")]),_c('ul',{staticClass:"navbar-nav list-unstyled mb-0"},[_vm._m(0),_vm._m(1),_vm._m(2),_c('li',{staticClass:"nav-item"},[_c('FormContactMoldal',{staticClass:"nav-link"})],1)])]),_vm._m(3),_vm._m(4)]),_vm._m(5)])]),_vm._m(6)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"mb-3 nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"#informacion"}},[_vm._v("Inicio")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"mb-3 nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"#informacion"}},[_vm._v("Información")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"mb-3 nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"#descripcion"}},[_vm._v("Descripción")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-sm-4 mt-6 mt-sm-0 navbar-dark"},[_c('h5',{staticClass:"mb-4 text-white"},[_vm._v("Formas de contacto")]),_c('ul',{staticClass:"navbar-nav list-unstyled mb-0"},[_c('li',{staticClass:"mb-3 nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"https://wa.me/+573008382223","target":"_blank"}},[_vm._v(" Whatsapp")])]),_c('li',{staticClass:"mb-3 nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"mailto:ionico@osherbiotecnologica.com"}},[_vm._v("Email")])]),_c('li',{staticClass:"mb-3 nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"tel:+573008382223"}},[_vm._v("llamada")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-sm-4 mt-6 mt-sm-0 navbar-dark"},[_c('h5',{staticClass:"mb-4 text-white"},[_vm._v("Legal")]),_c('ul',{staticClass:"navbar-nav list-unstyled mb-0"},[_c('li',{staticClass:"mb-3 nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"terms-and-conditions.html"}},[_vm._v("Terminos del servicio")])]),_c('li',{staticClass:"mb-3 nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"privacy-policy.html"}},[_vm._v("Politica de privacidad")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"#"}},[_vm._v("Suporte")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col-12 col-sm-6"},[_c('a',{staticClass:"footer-logo text-white h2 mb-0",attrs:{"href":"index.html"}},[_c('img',{staticClass:"img-fluid",attrs:{"src":"images/logo1.png","alt":""}})])]),_c('div',{staticClass:"col-12 col-sm-6 mt-6 mt-sm-0"},[_c('ul',{staticClass:"list-inline mb-0"},[_c('li',{staticClass:"list-inline-item"},[_c('a',{staticClass:"text-light ic-2x",attrs:{"href":"#"}},[_c('i',{staticClass:"la la-facebook"})])]),_c('li',{staticClass:"list-inline-item"},[_c('a',{staticClass:"text-light ic-2x",attrs:{"href":"#"}},[_c('i',{staticClass:"la la-instagram"})])]),_c('li',{staticClass:"list-inline-item"},[_c('a',{staticClass:"text-light ic-2x",attrs:{"href":"#"}},[_c('i',{staticClass:"la la-twitter"})])]),_c('li',{staticClass:"list-inline-item"},[_c('a',{staticClass:"text-light ic-2x",attrs:{"href":"#"}},[_c('i',{staticClass:"la la-linkedin"})])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row text-white text-center mt-8"},[_c('div',{staticClass:"col"},[_c('hr',{staticClass:"mb-8"}),_vm._v(" Copyright 2022 Ionico | Diseño web: "),_c('u',[_c('a',{staticClass:"text-white",attrs:{"href":"#"}},[_vm._v("www.FEPCOGrafic.com")])]),_vm._v(" | All Rights Reserved ")])])
}]

export { render, staticRenderFns }