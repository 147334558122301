import Vue from "vue";
import App from "./App.vue";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Vuelidate from "vuelidate";
import VueRouter from "vue-router";
import HomeView from "./views/HomeView.vue";
import RegisterView from "./views/RegisterView.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VeeValidate, { Validator } from "vee-validate";
import es from "vee-validate/dist/locale/es";


Vue.config.productionTip = false;

Vue.use(VueRouter);
const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      component: HomeView,
    },
    {
      path: "/register",
      component: RegisterView,
    },
  ],
});
Vue.use(Vuelidate);
Vue.component("v-select", vSelect);
Vue.use(VueSweetalert2);
Vue.use(VeeValidate);
Validator.localize("es", es);
var CryptoTS = require("crypto-ts");
var key = 'kaiokenx200alafergax100alafergax50alaferga';
const cryptsPlugin = {}

cryptsPlugin.install = function(Vue)
{
    Vue.prototype.$myCryptorts = {
        encode: (data) => {
         return CryptoTS.AES.encrypt(JSON.stringify(data), key).toString()   
        },
        decode: (data) => {
         return JSON.parse(CryptoTS.AES.decrypt(data.toString(), key).toString(CryptoTS.enc.Utf8))  
        }
    }
}

Vue.use(cryptsPlugin)

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
