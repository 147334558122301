<template>
  <header class="site-header">
    <div id="header-wrap">
      <div class="container">
        <div class="row">
          <!--menu start-->
          <div class="col">
            <nav class="navbar navbar-expand-lg navbar-light">
              <router-link to ="/">
              <a class="navbar-brand logo text-dark h2 mb-0" href="">
                <img src="images/logo.png" class="img-fluid" alt="..." />
              </a>
              </router-link>
              <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav ms-auto">
                  <li class="nav-item dropdown">
                    <a class="nav-link" href="#informacion" data-bs-toggle="dropdown"
                      >Inicio</a
                    >
                  </li>
                  <li class="nav-item dropdown">
                    <a
                      class="nav-link"
                      href="#informacion"
                      data-bs-toggle="dropdown"
                      >Información</a
                    >
                  </li>
                  <li class="nav-item dropdown">
                    <a
                      class="nav-link"
                      href="#descripcion"
                      data-bs-toggle="dropdown"
                      >Descripción</a
                    >
                  </li>
                  <li class="nav-item dropdown">
                    <a
                      class="nav-link"
                      href="#planes"
                      data-bs-toggle="dropdown"
                      >Planes</a
                    >
                  </li>
                  <li class="nav-item dropdown">
                    <a
                      class="nav-link"
                      href="#contactenos"
                      data-bs-toggle="dropdown"
                      >Contáctenos</a
                    >
                  </li>
                </ul>
              </div>
              <a class="btn btn-primary ms-8 d-none d-md-block" href="#"
                >Tutoriales</a
              >
            </nav>
          </div>
          <!--menu end-->
        </div>
      </div>
    </div>
  </header>
</template>
<script>
export default {
  name: "AppNavBar",
};
</script>
