<template>
  <div id="app">
    <NavBar />
   <router-view></router-view>
   <Footer />
  </div>
</template>

<script>
import NavBar from './components/header/NavBar.vue';
import Footer from './components/footer/footer.vue';

export default {
    name: "App",
    components: { NavBar, Footer }
};
</script>
