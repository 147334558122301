<template>
  <div class="page-content">
    <!--services start-->

    <section class="text-center">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="p-7 rounded bg-white shadow">
              <div class="row align-items-center">
                <div class="col-xl-4 col-lg-4 mb-8 mb-lg-0">
                  <div>
                    <div>
                      <img
                        class="img-fluid"
                        src="images/services/01.png"
                        alt=""
                      />
                    </div>
                    <h5 class="mt-4 mb-3">Overview</h5>
                    <p>
                      Podrás asignar los usuarios que pueden imprimir o
                      descargar documentos.
                    </p>
                    <a class="btn-link" href="#">Ver más</a>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-sm-6">
                  <div>
                    <div>
                      <img
                        class="img-fluid"
                        src="images/services/02.png"
                        alt=""
                      />
                    </div>
                    <h5 class="mt-4 mb-3">Video Chat</h5>
                    <p>
                      Podrás solicitar la creación y corrección de los
                      documentos que se encuentran en proceso.
                    </p>
                    <a class="btn-link" href="#">Ver Más</a>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-sm-6 mt-6 mt-sm-0">
                  <div>
                    <div>
                      <img
                        class="img-fluid"
                        src="images/services/03.png"
                        alt=""
                      />
                    </div>
                    <h5 class="mt-4 mb-3">Unique Data</h5>
                    <p>
                      Ionico genera de forma automática los códigos que se le
                      deben asignar a cada documento.
                    </p>
                    <a class="btn-link" href="#">Ver Más</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--services end-->

    <!--about start-->

    <section class="pt-0">
      <div class="container">
        <div class="row align-items-center justify-content-between">
          <div class="col-12 col-lg-6 mb-6 mb-lg-0">
            <img src="images/about/14.png" alt="Image" class="img-fluid" />
          </div>
          <div class="col-12 col-lg-6 col-xl-5">
            <div>
              <span class="badge badge-primary-soft p-2">
                <i class="la la-exclamation ic-3x rotation"></i>
              </span>
              <h2 class="mt-3">
                Es una completa herramienta que permite administrar y gestionar
              </h2>
              <p class="lead">
                toda la documentación de tu sistema de gestión de calidad, de
                forma efectiva, coordinada y trazable. También cuenta con la
                capacidad de administrar y gestionar todas las pqrf, acciones
                correctivas, preventivas y de mejora. Con Ionico podrás asignar
                tareas y trabajar con los diferentes equipos de la compañía de
                forma eficiente y en tiempo real.
              </p>
              <h2 id="descripcion"></h2>
            </div>
            <div class="d-flex">
            <a href="#" class="btn btn-primary text-white text-start me-1">
              <div class="d-inline-block">
                <small class="d-block">Más información</small>
              </div>
            </a>
            <FormDemoModal titulo="Solicita una Demo" classes="btn btn-dark text-white text-start"  />
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--about end-->

    <!--work start-->

    <section>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <nav>
              <div
                class="nav nav-tabs d-flex justify-content-center border-0"
                id="nav-tab"
                role="tablist"
              >
                <a
                  class="nav-item nav-link border-0 active"
                  id="nav-tab-1"
                  data-bs-toggle="tab"
                  href="descripcion#tab-1"
                  role="tab"
                  aria-controls="tab-1"
                  aria-selected="true"
                  >Evite retrocesos</a
                >
                <a
                  class="nav-item nav-link border-0"
                  id="nav-tab-2"
                  data-bs-toggle="tab"
                  href="descripcion#tab-2"
                  role="tab"
                  aria-controls="tab-2"
                  aria-selected="false"
                  >IONICO es la herramienta que necesitas!</a
                >
                <a
                  class="nav-item nav-link border-0"
                  id="nav-tab-3"
                  data-bs-toggle="tab"
                  href="descripcion#tab-3"
                  role="tab"
                  aria-controls="tab-3"
                  aria-selected="false"
                  >Soluciones IONICO</a
                >
              </div>
            </nav>
            <div class="tab-content mt-8" id="nav-tabContent">
              <div
                class="tab-pane fade show active"
                id="tab-1"
                role="tabpanel"
                aria-labelledby="nav-tab-1"
              >
                <div
                  class="row align-items-center justify-content-between mb-10"
                >
                  <div class="col-12 col-lg-6 mb-6 mb-lg-0">
                    <img
                      src="images/svg/10.png"
                      alt="Image"
                      class="img-fluid"
                    />
                  </div>
                  <div class="col-12 col-lg-6 col-xl-5">
                    <div>
                      <h4 class="mt-3">
                        El control documental manual puede ser una verdadera
                        pesadilla…
                      </h4>
                      <p class="lead"></p>
                      <p class="mb-0">
                        ¿Aun administras y gestionas de forma manual toda la
                        documentación de tu sistema documental? No pierdas
                        tiempo y recursos… No te desgastes imprimiendo
                        documentos en papel reciclado. ¿No tienes el control de
                        las versiones de los documentos últimos actualizados?<br /><br />

                        ¿Tienes problemas en administrar y generar los códigos
                        de los documentos?
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="tab-2"
                role="tabpanel"
                aria-labelledby="nav-tab-2"
              >
                <div
                  class="row align-items-center justify-content-between mb-10"
                >
                  <div class="col-12 col-lg-6 mb-6 mb-lg-0">
                    <img
                      src="images/svg/11.png"
                      alt="Image"
                      class="img-fluid"
                    />
                  </div>
                  <div class="col-12 col-lg-6 col-xl-5">
                    <div>
                      <h4 class="mt-3">Con Ionico podrás</h4>
                      <p class="lead">
                        1. Crear y administrar los usuarios por perfiles 2.
                        Podrás asignar los usuarios que pueden imprimir o
                        descargar documentos<br />
                        3. Podrás solicitar la creación y corrección de los
                        documentos que se encuentran en proceso<br />
                        4. Podrás revisar y aprobar los documentos dejando la
                        trazabilidad necesaria para cumplir cualquier norma
                        ISO.<br />
                        5. Ionico genera de forma automática los códigos que se
                        le deben asignar a cada documento<br />
                        6. Ionico te permite gestionar y administrar tus PQRF<br />
                        7. Con ionico podrás asignar tareas a cualquier miembro
                        de tu equipo de trabajo.<br />
                        8. Ionico funciona en un entorno 100% web que te permite
                        utilizarlo en diferentes cedes al tiempo a nivel
                        mundial.<br />
                        9. Ionico cuenta con la capacidad de archivar los
                        documentos que son actualizados con una nueva
                        versión.<br />
                        10. Con ionico podrás realizar tus solicitudes de
                        actualización de documentos
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="tab-3"
                role="tabpanel"
                aria-labelledby="nav-tab-3"
              >
                <div
                  class="row align-items-center justify-content-between mb-10"
                >
                  <div class="col-12 col-lg-6 mb-6 mb-lg-0">
                    <img
                      src="images/svg/09.png"
                      alt="Image"
                      class="img-fluid"
                    />
                  </div>
                  <div class="col-12 col-lg-6 col-xl-5">
                    <div>
                      <h4 class="mt-3">Con ionico lograras cumplir con:</h4>
                      <p class="lead">
                        • Sistema de gestión de la calidad ISO 9001<br />
                        • Seguridad y salud en el trabajo ISO 45001<br />
                        • ISO 17025<br />
                        • ISO 13785<br />
                        • ISO 14644<br />

                        • Gestión del conocimiento<br />

                        • Continuidad de negocio<br />

                        • HSEQ Integración de ISO 9001 + 14001 + 45001<br />

                        • Sarlaft - Sagrilaft Colombia<br />

                        • Riesgos, Dafp, Saro<br />

                        • Seguridad de la información<br />

                        • Medio ambiente<br />

                        • Gestión y mejora empresarial por procesos<br />

                        • Seguridad de la información<br />

                        • Modelo integrado de planeación y gestión<br />

                        • Salud<br />

                        • Sistema de gestión de control y seguridad
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--work end-->

    <!--screenshots start-->

    <section>
      <div class="container">
        <div class="row justify-content-center text-center">
          <div class="col-12 col-md-12 col-lg-8 mb-8 mb-lg-0">
            <div class="mb-5">
              <span class="badge badge-primary-soft p-2">
                <i class="la la-star ic-3x rotation"></i>
              </span>
              <h2 class="mt-3">
                ¿Porque su empresa debería gestionar con ionico?
              </h2>
              <p class="lead mb-0">
                Ionico es sinónimo de innovación, agilidad, simpleza y constante
                evolución; nuestra aplicación se basa en un sistema estructural
                funcional de alta tecnología creado para facilitar la gestión y
                el cumplimiento de cada estándar, norma o regulación y por
                supuesto escalar a otro nivel toda la organización de su
                empresa.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--screenshots end-->
  </div>
</template>
<script>
import FormDemoModal from '../formModal/formDemoModal.vue';



export default {
    name: "PageContent",
    components: { FormDemoModal }
};
</script>
