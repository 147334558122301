<template>
  <div class="subscribe-form bg-warning-soft p-5 rounded">
    <h5 class="mb-4 text-white">Más información</h5>
    <h6 class="text-light">¡Deseo recibir más información!</h6>
    <form @submit.prevent="submitForm" class="group">
      <input
        v-validate="'required'"
        v-model="data.email"
        type="email"
        name="Email"
        class="email form-control"
        placeholder="Email"
        style="height: 60px"
      /><br />
      <div v-show="errors.has('Email')" class="alert alert-danger">
        {{ errors.first("Email") }}
      </div>
      <input
        v-validate="'required'"
        v-model="data.company"
        type="text"
        name="empresa"
        class="email form-control"
        placeholder="Empresa"
        style="height: 60px"
      />
      <div v-show="errors.has('empresa')" class="alert alert-danger">
        {{ errors.first("empresa") }}
      </div>
      <button
        class="btn btn-outline-light btn-block mt-3 mb-2"
        type="submit"
      >Enviar
      </button>
    </form>
    <small class="text-light"
      >Daremos respuesta a su consulta en el menor tiempo posible...</small
    >
  </div>
</template>
<script>
import axios from 'axios';
export default {
  name: "formContact",
  data() {
    return {
      plan: false,
      submited: false,
      data: {
        email: "",
        company: "",
      },
    };
  },
  methods: {
    submitForm() {
      this.$validator.validate().then((result) => {
        if (result) {
           this.$swal({
        title: "Por favor, confime su información",
        html: `
          <div class="row">
            <h2>Información personal</h2>
              <p class="fw-bold">Empresa: <span class="fw-lighter">${this.data.company}</span></p>
              <p class="fw-bold">Email <span class="fw-lighter">${this.data.email}</span></p>
          </div>
        `,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, enviar!",
        cancelButtonText: "Cancelar",
        confirmButtonClass: "btn btn-success",
        cancelButtonClass: "btn btn-danger",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
            axios
              .post(
                "https://ionicogd.ionico.com.co/request-external/request-info",
                this.data
              )
              .then(async (response) => {
                this.$swal({
                  title: "Solicitud enviada",
                  text: "Te contactaremos muy pronto",
                  type: "success",
                  confirmButtonText: "Cerrar",
                  confirmButtonClass: "btn btn-success",
                  buttonsStyling: false,
                  reverseButtons: true,
                }).then((result) => {
                  if (result.value) {
                    console.log(response);
                    this.$emit("close");
                  }
                });
              })
              .catch(async (error) => {
                this.$swal({
                  title:
                    "No se pudo enviar la solicitud, por favor intentalo de nuevo mas tarde",
                  text: error.message,
                  type: "error",
                  confirmButtonText: "Cerrar",
                  confirmButtonClass: "btn btn-success",
                  buttonsStyling: false,
                  reverseButtons: true,
                });
              });
          this.resetForm();
        }
      });
          return;
        }
      });
    },

    resetForm() {
      this.data = {
        email: "",
        company: "",
      };
    },
  },
};
</script>
