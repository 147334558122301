<template>
<section>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-12 col-lg-5 col-xl-6 order-lg-1 mb-8 mb-lg-0">
        <!-- Image -->
        <img src="images/hero/04gif/04.gif" class="img-fluid" alt="...">
      </div>
      <div class="col-12 col-lg-7 col-xl-6">
        <!-- Heading -->
        <h6 class="border rounded-pill px-3 py-2 text-muted d-inline-block"> <span class="badge rounded-pill bg-primary me-3">Nueva</span> herramienta para su empresa</h6>
        <h1 class="display-4 mt-3">
              Software <span class="text-primary font-w-5">Ionico</span> para gestión empresarial        
            </h1>
        <!-- Text -->
        
        <p class="lead text-muted">Completa herramienta que permite administrar y gestionar toda la documentación de tu sistema de gestión de calidad.</p>
        <!-- Button -->
        <FormDemoModal :isRequestDemo="true" texto="Diligencia este formulario para continuar" titulo="Solicitar Demo" classes="btn-primary"/>
        <!-- Button -->

       <!--  <a href="http://vimeo.com/99025203" class="popup-vimeo link-title"> <i class="la la-play-circle me-1 ic-3x align-middle line-h-0"></i> Ver video</a> -->
      </div>
    </div>
    <!-- / .row -->
  </div>
  <!-- / .container -->
</section>
</template>
<script>
import FormDemoModal from '../formModal/formDemoModal.vue';


    export default {
    name: "HeroSection",
    components: { FormDemoModal }
}
</script>