var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row align-items-center"},[_vm._m(0),_c('div',{staticClass:"col-12 col-md-12 col-lg-8"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-12 col-md-6 mb-8 mb-md-0"},[_c('div',{staticClass:"card border-0 shadow"},[_c('div',{staticClass:"card-body py-8 px-6",attrs:{"id":"planes"}},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_c('p',{staticClass:"text-center text-muted"},[_vm._v("Mensuales")]),_c('FormPlanBasicoModal',{attrs:{"texto":"Adquiere tu plan","size":"col-12","titulo":"Adquir plan","classes":"btn btn-block btn-primary mt-5"}})],1)])]),_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"card border-0 shadow"},[_c('div',{staticClass:"card-body py-8 px-6"},[_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14),_c('p',{staticClass:"text-center text-muted"},[_vm._v("Mensual")]),_c('FormPlanProfesionalModal',{attrs:{"texto":"Adquiere tu plan","size":"col-12","titulo":"Adquir plan","classes":"btn btn-block btn-primary mt-5"}})],1)])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-md-12 col-lg-4 order-lg-1 mb-8 mb-lg-0"},[_c('div',{staticClass:"mb-0"},[_c('span',{staticClass:"badge badge-primary-soft p-2"},[_c('i',{staticClass:"la la-money ic-3x rotation"})]),_c('h2',{staticClass:"mt-3"},[_vm._v("Herramienta completa que permite administrar y gestionar")]),_c('p',{staticClass:"lead mb-0"},[_vm._v("Nuestra aplicación empresarial se basa en un sistema estructural funcional de alta tecnología ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center mb-5"},[_c('span',{staticClass:"badge text-dark shadow"},[_c('span',{staticClass:"h6 text-uppercase"},[_vm._v("Plan Básico")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-5"},[_c('img',{staticClass:"img-fluid",attrs:{"src":"images/svg/01.svg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-start justify-content-between"},[_c('p',[_vm._v("Programación")]),_c('div',{staticClass:"ms-4"},[_c('i',{staticClass:"la la-check text-primary fw-bold"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-start justify-content-between"},[_c('p',[_vm._v("Ejecución")]),_c('div',{staticClass:"ms-4"},[_c('i',{staticClass:"la la-check text-primary fw-bold"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-start justify-content-between"},[_c('p',[_vm._v("Herramientas")]),_c('div',{staticClass:"ms-4"},[_c('i',{staticClass:"la la-check text-primary fw-bold"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-start justify-content-between"},[_c('p',[_vm._v("Implementación ($3.500.000)")]),_c('div',{staticClass:"ms-4"},[_c('i',{staticClass:"la la-check text-primary fw-bold"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-center mt-5"},[_c('span',{staticClass:"h3 mb-0 mt-2"},[_vm._v("$")]),_c('span',{staticClass:"price display-3 text-primary font-w-6"},[_vm._v("600.000")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center mb-5"},[_c('span',{staticClass:"badge text-dark shadow"},[_c('span',{staticClass:"h6 text-uppercase"},[_vm._v("Plan Profesional")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-5"},[_c('img',{staticClass:"img-fluid",attrs:{"src":"images/svg/02.svg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-start justify-content-between"},[_c('p',[_vm._v("Programación")]),_c('div',{staticClass:"ms-4"},[_c('i',{staticClass:"la la-check text-primary fw-bold"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-start justify-content-between"},[_c('p',[_vm._v("Ejecución")]),_c('div',{staticClass:"ms-4"},[_c('i',{staticClass:"la la-check text-primary fw-bold"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-start justify-content-between"},[_c('p',[_vm._v("Herramientas")]),_c('div',{staticClass:"ms-4"},[_c('i',{staticClass:"la la-check text-primary fw-bold"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-start justify-content-between"},[_c('p',[_vm._v("Implementación ($3.500.000)")]),_c('div',{staticClass:"ms-4"},[_c('i',{staticClass:"la la-check text-primary fw-bold"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-center mt-5"},[_c('span',{staticClass:"h3 mb-0 mt-2"},[_vm._v("$")]),_c('span',{staticClass:"price display-3 text-primary font-w-6"},[_vm._v("1.000.000")])])
}]

export { render, staticRenderFns }