<template>
  <footer
    id="contactenos"
    class="py-11 bg-primary position-relative"
    data-bg-img="images/bg/03.png"
  >
    <div class="shape-1" style="height: 150px; overflow: hidden">
      <svg
        viewBox="0 0 500 150"
        preserveAspectRatio="none"
        style="height: 100%; width: 100%"
      >
        <path
          d="M0.00,49.98 C150.00,150.00 271.49,-50.00 500.00,49.98 L500.00,0.00 L0.00,0.00 Z"
          style="stroke: none; fill: #fff"
        ></path>
      </svg>
    </div>
    <div class="container mt-7">
      <div class="row">
        <div class="col-12 col-lg-5 col-xl-4 me-auto mb-6 mb-lg-0">
          <FormContact />
        </div>
        <div class="col-12 col-lg-6 col-xl-7">
          <div class="row">
            <div class="col-12 col-sm-4 navbar-dark">
              <h5 class="mb-4 text-white">Paginas</h5>
              <ul class="navbar-nav list-unstyled mb-0">
                <li class="mb-3 nav-item">
                  <a class="nav-link" href="#informacion">Inicio</a>
                </li>
                <li class="mb-3 nav-item">
                  <a class="nav-link" href="#informacion">Información</a>
                </li>
                <li class="mb-3 nav-item">
                  <a class="nav-link" href="#descripcion">Descripción</a>
                </li>
                <li class="nav-item">
                  <FormContactMoldal class="nav-link" />
                </li>
              </ul>
            </div>
            <div class="col-12 col-sm-4 mt-6 mt-sm-0 navbar-dark">
              <h5 class="mb-4 text-white">Formas de contacto</h5>
              <ul class="navbar-nav list-unstyled mb-0">
                <li class="mb-3 nav-item">
                  <a class="nav-link" href="https://wa.me/+573008382223" target="_blank">
                    Whatsapp</a
                  >
                </li>
                <li class="mb-3 nav-item">
                  <a class="nav-link" href="mailto:ionico@osherbiotecnologica.com">Email</a>
                </li>
                <li class="mb-3 nav-item">
                  <a class="nav-link" href="tel:+573008382223">llamada</a>
                </li>
              </ul>
            </div>
            <div class="col-12 col-sm-4 mt-6 mt-sm-0 navbar-dark">
              <h5 class="mb-4 text-white">Legal</h5>
              <ul class="navbar-nav list-unstyled mb-0">
                <li class="mb-3 nav-item">
                  <a class="nav-link" href="terms-and-conditions.html"
                    >Terminos del servicio</a
                  >
                </li>
                <li class="mb-3 nav-item">
                  <a class="nav-link" href="privacy-policy.html"
                    >Politica de privacidad</a
                  >
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">Suporte</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-12 col-sm-6">
              <a class="footer-logo text-white h2 mb-0" href="index.html">
                <img class="img-fluid" src="images/logo1.png" alt="" />
              </a>
            </div>
            <div class="col-12 col-sm-6 mt-6 mt-sm-0">
              <ul class="list-inline mb-0">
                <li class="list-inline-item">
                  <a class="text-light ic-2x" href="#"
                    ><i class="la la-facebook"></i
                  ></a>
                </li>
                <li class="list-inline-item">
                  <a class="text-light ic-2x" href="#"
                    ><i class="la la-instagram"></i
                  ></a>
                </li>
                <li class="list-inline-item">
                  <a class="text-light ic-2x" href="#"
                    ><i class="la la-twitter"></i
                  ></a>
                </li>
                <li class="list-inline-item">
                  <a class="text-light ic-2x" href="#"
                    ><i class="la la-linkedin"></i
                  ></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="row text-white text-center mt-8">
        <div class="col">
          <hr class="mb-8" />
          Copyright 2022 Ionico | Diseño web:
          <u><a class="text-white" href="#">www.FEPCOGrafic.com</a></u> | All
          Rights Reserved
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import FormContact from "../formModal/formContact.vue";
import FormContactMoldal from "../formModal/formContactMoldal.vue";

export default {
  name: "footerComponent",
  components: { FormContact, FormContactMoldal },
};
</script>
