<template>
  <div>
    <button
      type="button"
      :class="' shadow me-1 btn ' + classes"
      data-bs-toggle="modal"
      data-bs-target="#proFormModal"
    >
      {{ titulo }}
    </button>

    <div
      class="modal fade"
      id="proFormModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ texto }}</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form v-on:submit.prevent="enviarPlanForm">
              <div class="col-12">
                <label for="inputName" class="form-label">Nombre</label>
                <input
                  type="text"
                  class="form-control"
                  id="inputName"
                  v-model="data.nombre"
                  autocomplete="off"
                />
                <div
                  class="alert alert-danger"
                  v-if="submited && !$v.data.nombre.required"
                >
                  Este campo es obligatorio
                </div>
                <div
                  class="alert alert-danger"
                  v-if="submited && !$v.data.nombre.minLength"
                >
                  Este campo debe tener al menos 3 caracteres
                </div>
              </div>
              <div class="col-12">
                <label for="inputName" class="form-label">Apellido</label>
                <input
                  type="text"
                  class="form-control"
                  id="inputName"
                  v-model="data.apellido"
                  autocomplete="off"
                />
                <div
                  class="alert alert-danger"
                  v-if="submited && !$v.data.apellido.required"
                >
                  Este campo es obligatorio
                </div>
                <div
                  class="alert alert-danger"
                  v-if="submited && !$v.data.apellido.minLength"
                >
                  Este campo debe tener al menos 3 caracteres
                </div>
              </div>
              <div class="col-12">
                <label for="inputAddress2" class="form-label">Telefono</label>
                <input
                  type="phone"
                  class="form-control"
                  id="inputPhone"
                  v-model="data.telefono"
                />
                <div
                  class="alert alert-danger"
                  v-if="submited && !$v.data.telefono.required"
                >
                  Este campo es obligatorio
                </div>
                <div
                  class="alert alert-danger"
                  v-if="submited && !$v.data.telefono.numeric"
                >
                  Ingresa un numero de telefono valido Ej: 3201234567
                </div>
                <div
                  class="alert alert-danger"
                  v-if="submited && !$v.data.telefono.minLength"
                >
                  Este campo debe tener al menos 7 caracteres
                </div>
              </div>
              <div class="col-12">
                <label for="inputEmail4" class="form-label"
                  >Correo Electronico</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="inputEmail4"
                  v-model="data.email"
                />
                <div
                  class="alert alert-danger"
                  v-if="submited && !$v.data.email.required"
                >
                  Este campo es obligatorio
                </div>
                <div
                  class="alert alert-danger"
                  v-if="submited && !$v.data.email.email"
                >
                  Ingresa una direccion de correo valida
                </div>
              </div>
              <div class="col-12">
                <label for="inputCompany" class="form-label">Empresa</label>
                <input
                  type="text"
                  class="form-control"
                  id="inputCompany"
                  placeholder="Opcional"
                  v-model="data.empresa"
                />
              </div>
              <div class="col-12">
                <label for="inputSector" class="form-label">Sector</label>
                <input
                  type="text"
                  class="form-control"
                  id="inputSector"
                  placeholder="Opcional"
                  v-model="data.sector"
                />
              </div>
              <div class="col-12 mt-3 d-flex justify-content-end">
                <button type="submit" class="btn btn-primary m-2">
                  Enviar
                </button>
                <button
                  type="button"
                  class="btn btn-secondary m-2"
                  data-bs-dismiss="modal"
                >
                  Cerrar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength, email, numeric } from "vuelidate/lib/validators";
import axios from "axios";

export default {
  name: "FormPlanBasicoModal",
  props: {
    classes: String,
    titulo: String,
    texto: String,
  },
  data() {
    return {
      plan: false,
      submited: false,
      data: {
        nombre: "",
        apellido: "",
        telefono: "",
        email: "",
        empresa: "",
        sector: "",
        isRequestDemo: false,
        planName: "Plan profesional",
        planValue: 1000000,
      },
    };
  },
  methods: {
    enviarPlanForm() {
      this.submited = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }
      let data = {
        name: this.data.nombre,
        lastName: this.data.apellido,
        phone: this.data.telefono,
        email: this.data.email,
        company: this.data.empresa,
        economic_sector: this.data.sector,
        isRequestDemo: this.data.isRequestDemo,
        planValue: this.data.planValue,
        planName: this.data.planName,
      };
      this.$swal({
        title: "Por favor, confime su información",
        html: `
          <div class="row">
            <div class="col-12">
              <p class="fw-bold">Nombre: <span class="fw-lighter">${
                data.name
              }</span></p>
              <p class="fw-bold">Apellido: <span class="fw-lighter">${
                data.lastName
              }</span></p>
              <p class="fw-bold">Telefono: <span class="fw-lighter">${
                data.phone
              }</span></p>
              <p class="fw-bold">Email: <span class="fw-lighter">${
                data.email
              }</span></p>
              <p class="fw-bold">Empresa: <span class="fw-lighter">${
                data.company ? data.company : "No especificado"
              }</span></p>
              <p class="fw-bold">Sector: <span class="fw-lighter">${
                data.economic_sector ? data.economic_sector : "No especificado"
              }</span></p>
              <p class="fw-bold">Plan: <span class="fw-lighter">${
                data.planName
              }</span></p>
              <p class="fw-bold">Valor: <span class="fw-lighter">$ ${
                data.planValue
              }</span></p>
            </div>
          </div>
        `,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, enviar!",
        cancelButtonText: "Cancelar",
        confirmButtonClass: "btn btn-success",
        cancelButtonClass: "btn btn-danger",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          axios
            .post(
              "https://ionicogd.ionico.com.co/request-external/request-demo",
              data
            )
            .then((response) => {
              this.$swal({
                title: "Solicitud enviada",
                text: "Te contactaremos muy pronto",
                type: "success",
                confirmButtonText: "Cerrar",
                confirmButtonClass: "btn btn-success",
                buttonsStyling: false,
                reverseButtons: true,
              }).then((result) => {
                if (result.value) {
                  console.log(response);
                  this.$emit("close");
                }
              });
            })
            .catch((error) => {
              this.$swal({
                title:
                  "No se pudo enviar la solicitud, por favor intentalo de nuevo mas tarde",
                text: error.message,
                type: "error",
                confirmButtonText: "Cerrar",
                confirmButtonClass: "btn btn-success",
                buttonsStyling: false,
                reverseButtons: true,
              });
            });
        }
      });
    },
  },
  validations: {
    data: {
      nombre: {
        required,
        minLength: minLength(3),
      },
      apellido: {
        required,
        minLength: minLength(3),
      },
      telefono: {
        required,
        minLength: minLength(7),
        numeric,
      },
      email: {
        required,
        email,
      },
    },
  },
};
</script>
