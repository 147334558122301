<template>
  <div class="container">
    <h2 class="border-bottom pb-2 mb-3">Registrar mi empresa</h2>
    <h3 class="mb-4">Informacion del contacto del administrador</h3>
    <form @submit.prevent="handleSubmit" class="row g-4">
      <div class="col-4">
        <label for="inputNombre" class="form-label">Nombre</label>
        <input
          type="text"
          class="form-control"
          id="inputNombre"
          placeholder="Ingrese aqui su nombre"
          v-validate="'required'"
          name="nombre"
          v-model="admin.data.name.first"
        />
        <div v-show="errors.has('nombre')" class="alert alert-danger">
          {{ errors.first("nombre") }}
        </div>
      </div>
      <div class="col-4">
        <label for="inputPApellido" class="form-label">Apellido</label>
        <input
          type="Text"
          class="form-control"
          id="inputApellido"
          placeholder="Ingrese aqui su apellido"
          v-validate="'required'"
          name="apellido"
          v-model="admin.data.name.last"
        />
         <div v-show="errors.has('apellido')" class="alert alert-danger">
          {{ errors.first("apellido") }}
        </div>
      </div>
      <div class="col-4">
        <label for="inputTipoCedula" class="form-label"
          >Tipo de Identificacion</label
        >
        <v-select
          v-model="admin.data.dni.dType"
          :options="tiposIdentificacion"
          label="nombre"
          v-validate="'required'"
          name="tipo de identificacion"
          :placeholder="'Seleccione un tipo de identificacion'"
        ></v-select>
        <div v-show="errors.has('tipo de identificacion')" class="alert alert-danger">
          {{ errors.first("tipo de identificacion") }}
        </div>
      </div>
      <div class="col-4">
        <label for="inputNumIdentificacion" class="form-label"
          >Numero de identificacion</label
        >
        <input
          type="text"
          class="form-control"
          id="inputNumIdentificacion"
          placeholder="Ingrese aqui su numero de identificacion"
          v-validate="'required'"
          name="numero de identificacion"
          v-model="admin.data.dni.dni"
        />
        <div v-show="errors.has('numero de identificacion')" class="alert alert-danger">
          {{ errors.first("numero de identificacion") }}
        </div>
      </div>
      <div class="col-4">
        <label for="FechaNacimiento" class="form-label"
          >Fecha de nacimiento</label
        >
        <input
          type="date"
          class="form-control"
          id="inputFechaNacimiento"
          placeholder="Pick a date"
          v-validate="'required'"
          name="fecha de nacimiento"
          v-model="admin.data.birthdate"
        />
        <div v-show="errors.has('fecha de nacimiento')" class="alert alert-danger">
          {{ errors.first("fecha de nacimiento") }}
        </div>
      </div>
      <div class="col-4">
        <label for="inputPhone" class="form-label">Numero de telefono</label>
        <input
          type="text"
          class="form-control"
          id="inputNumTelefono"
          v-validate="'required'"
          name="numero de telefono"
          placeholder="Ingrese aqui su Numero de Telefono"
          v-model="admin.data.phone"
        />
        <div v-show="errors.has('numero de telefono')" class="alert alert-danger">
          {{ errors.first("numero de telefono") }}
        </div>
      </div>
      <div class="col-4">
        <Label class="form-label">Departamento</Label>
        <v-select
          v-model.trim="userDepartment"
          v-validate="'required'"
          name="departamento"
          label="text"
          :reduce="(departamento) => departamento.text"
          :options="departamentos"
          placeholder="-Todos-"
          @input="getCiudades()"
        ></v-select>
        <div v-show="errors.has('departamento')" class="alert alert-danger">
          {{ errors.first("departamento") }}
        </div>
      </div>
      <div class="col-4">
        <Label class="form-label">Ciudad</Label>
        <v-select
          v-model="admin.data.address.town"
          v-validate="'required'"
          name="ciudad"
          label="municipio"
          :options="ciudades"
          :reduce="(ciudad) => ciudad.municipio"
          placeholder="-Todos-"
        ></v-select>
        <div v-show="errors.has('ciudad')" class="alert alert-danger">
          {{ errors.first("ciudad") }}
        </div>
      </div>
      <div class="col-4">
        <label for="inputEmail" class="form-label">Correo electronico</label>
        <input
          v-model="admin.data.mail"
          v-validate="'required'"
          name="correo electronico"
          type="email"
          class="form-control"
          id="inputEmail"
          placeholder="Ingrese aqui su correo electronico"
        />
        <div v-show="errors.has('correo electronico')" class="alert alert-danger">
          {{ errors.first("correo electronico") }}
        </div>
      </div>
      <div class="col-4">
        <label for="inputNombreUsuario" class="form-label"
          >Nombre de usuario</label
        >
        <input
          v-model="admin.data.accessData.nickname"
          v-validate="'required'"
          name="nombre de usuario"
          type="text"
          class="form-control"
          id="inputNombreUsuario"
          placeholder="Ingrese aqui su nombre de usuario"
        />
        <div v-show="errors.has('nombre de usuario')" class="alert alert-danger">
          {{ errors.first("nombre de usuario") }}
        </div>
      </div>
      <div class="col-4">
        <label for="inputContraseña" class="form-label">Contraseña</label>
        <input
          v-model="admin.data.accessData.password"
          v-validate="'required|min:8'"
          name="contraseña"
          type="password"
          ref="contraseña"
          class="form-control"
          id="password"
          placeholder="Ingrese aqui su contraseña"
        />
        <div v-show="errors.has('contraseña')" class="alert alert-danger">
          {{ errors.first("contraseña") }}
        </div>
        <div class="form-group form-check">
          <input
            type="checkbox"
            id="showpassword"
            class="form-check-input"
            @click="togglePassword"
            v-model="showpassword"
          />
          <label class="form-check-label" for="showpassword"
            >Mostrar Contraseña</label
          >
        </div>
      </div>

      <div class="col-4">
        <label for="inputConfirmarContraseña" class="form-label"
          >Confirmar Contraseña</label
        >
        <input
          v-model="confirmPassword"
          v-validate="'required|confirmed:contraseña'"
          name="confirmar contraseña"
          type="password"
          class="form-control"
          id="inputConfirmarContraseña"
          placeholder="Confirme su contraseña"
        />
        <div v-show="errors.has('confirmar contraseña')" class="alert alert-danger">
          {{ errors.first("confirmar contraseña") }}
        </div>
      </div>

      <h3 class="border-top pt-4 mb-4">Informacion de la empresa.</h3>
      <div class="col-4">
        <label for="inputNombreEmpresa" class="form-label"
          >Nombre de la empresa</label
        >
        <input
          v-model="admin.data.company.name"
           v-validate="'required'"
          name="nombre de la empresa"
          type="text"
          class="form-control"
          id="inputNombreEmpresa"
          placeholder="Ingrese aqui su nombre de la empresa"
        />
        <div v-show="errors.has('nombre de la empresa')" class="alert alert-danger">
          {{ errors.first("nombre de la empresa") }}
        </div>
      </div>
            <div class="col-4">
        <label for="inputNombreEmpresa" class="form-label"
          >Email empresarial</label
        >
        <input
          v-model="admin.data.company.email"
           v-validate="'required'"
          name="email de la empresa"
          type="email"
          class="form-control"
          id="inputemaildelaempresaEmpresa"
          placeholder="Ingrese aqui su email de la empresa "
        />
        <div v-show="errors.has('email de la empresa')" class="alert alert-danger">
          {{ errors.first("email de la empresa") }}
        </div>
      </div>
      <div class="col-4">
        <label for="inputNIT" class="form-label">NIT</label>
        <input
          v-model="admin.data.company.nit"
          v-validate="'required'"
          name="NIT"
          type="text"
          class="form-control"
          id="inputNIT"
          placeholder="Ingrese el nit de su empresa"
        />
        <div v-show="errors.has('NIT')" class="alert alert-danger">
          {{ errors.first("NIT") }}
        </div>
      </div>
      <div class="col-4">
        <label for="inputRepresentanteLegal" class="form-label"
          >Representante legal</label
        >
        <input
          v-model="admin.data.company.legalRepresentative"
          v-validate="'required'"
          name="representante legal"
          type="text"
          class="form-control"
          id="inputRepresentanteLegal"
          placeholder="Ingrese el representante legal de su empresa"
        />
        <div v-show="errors.has('representante legal')" class="alert alert-danger">
          {{ errors.first("representante legal") }}
        </div>
      </div>
      <div class="col-4">
        <label for="inputDepartamentoEmpresa" class="form-label"
          >Departamento</label
        >
        <v-select
          v-model="admin.data.company.address.country"
          v-validate="'required'"
          name="deparamento de la empresa"
          label="text"
          :reduce="(departamento) => departamento.text"
          :options="departamentosEmpresa"
          placeholder="-Todos-"
          @input="getCiudadesEmpresa()"
        ></v-select>
        <div v-show="errors.has('deparamento de la empresa')" class="alert alert-danger">
          {{ errors.first("deparamento de la empresa") }}
        </div>
      </div>
      <div class="col-4">
        <label for="inputCiudadEmpresa" class="form-label">Ciudad</label>
        <v-select
          v-model="admin.data.company.address.town"
          v-validate="'required'"
          name="ciudad de la empresa"
          label="municipio"
          :options="ciudadesEmpresa"
          :reduce="(ciudad) => ciudad.municipio"
          placeholder="-Todos-"
        ></v-select>
        <div v-show="errors.has('ciudad de la empresa')" class="alert alert-danger">
          {{ errors.first("ciudad de la empresa") }}
        </div>
      </div>
      <div class="col-4">
        <label for="inputDireccion" class="form-label"
          >Direccion de la empresa</label
        >
        <input
          v-model="admin.data.company.address.addInfo"
           v-validate="'required'"
          name="direccion de la empresa"
          type="text"
          class="form-control"
          id="inputDireccion"
          placeholder="Ingrese la direccion de la empresa"
        />
        <div v-show="errors.has('direccion de la empresa')" class="alert alert-danger">
          {{ errors.first("direccion de la empresa") }}
        </div>
      </div>
      <div class="col-4">
        <label for="inputDedica" class="form-label"
          >¿A que se dedica tu empresa?</label
        >
        <input
          v-model="admin.data.company.economyAtivity"
          v-validate="'required'"
          name="a que se dedica tu empresa"
          type="text"
          class="form-control"
          id="inputDedica"
          placeholder="A que se dedica la empresa"
        />
        <div v-show="errors.has('a que se dedica tu empresa')" class="alert alert-danger">
          {{ errors.first("a que se dedica tu empresa") }}
        </div>
      </div>
        <div class="col-4">
                <label class="form-label" for="formGroupExampleInput">RUT</label>
                <input
                  type="file"
                  v-validate="'required|size:10000|ext:pdf'"
                  name="RUT"
                  id="RUT"
                  class="input-file"
                  @change="handleFileUpload"
                />
                <label for="RUT" class="btn btn-tertiary js-labelFile">
                  <i class="icon fa fa-check"></i>
                  <span class="js-fileName">{{ nameFile }}</span>
                </label>
                <span v-show="errors.has('RUT')" class="is-danger">{{
                  errors.first("RUT")
                }}</span>
              </div>
      <div class="col-12 justify-content-start d-flex">
        <button type="submit" class="btn btn-primary col-2">Enviar</button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import departamentos from "../../data/departamentos.json";

export default {
  name: "RegisterView",
  data() {
    return {
      submited: false,
      departamentos: [],
      ciudades: [],
      ciudadesEmpresa: [],
      departamentosEmpresa: [],
      tiposIdentificacion: [
        "C.C",
      ],
      userDepartment: "",
      confirmPassword: "",
      companyDepartment: "",
      companyOcupation: "",
      showpassword: false,
      nameFile: "",
      files: [],
      admin: {
        data: {
          status: false,
          name: {
            first: "",
            last: "",
          },
          dni: {
            dType: "C.C",
            dni: "",
          },
          company: {
            requestManagerCode: "GS",
            email: "",
            solicitudeTypes: [
              {
                text: "Acción correctiva o preventiva",
                value: "45",
              },
              {
                text: "PQRF (Petición, Queja, Reclamo, Felicitaciones)",
                value: "46",
              },
              {
                text: "Reporte de evento adverso",
                value: "47",
              },
              {
                text: "Observación o acción de mejora",
                value: "48",
              },
            ],
            name: "",
            nit: "",
            economyAtivity: "",
            rut: "",
            logo: "resources/img/stark12343",
            lisence: "",
            generalRols: [
              {
                name: "Director Técnico",
                module: "SF",
                rols: [
                  {
                    id: 0,
                    icon: "ifa fa fa-th",
                    item: "Farmacia",
                    items: [
                      {
                        id: 1,
                        url: "dispensation",
                        text: "Dispensación",
                      },
                    ],
                  },
                  {
                    id: 1,
                    icon: "ifa fa fa-user",
                    item: "Programación de Pacientes",
                    items: [
                      {
                        id: 0,
                        url: "patient",
                        text: "Creación",
                      },
                      {
                        id: 1,
                        url: "patient-programing",
                        text: "Programación",
                      },
                      {
                        id: 2,
                        url: "query-schedule",
                        text: "Consultar Agenda",
                      },
                    ],
                  },
                  {
                    id: 2,
                    icon: "ifa fa fa-list-alt",
                    item: "Listados",
                    items: [
                      {
                        id: 0,
                        url: "patient-list",
                        text: "Pacientes",
                        actions: [],
                      },
                      {
                        id: 1,
                        url: "thirds-list",
                        text: "Terceros",
                        actions: ["C", "U", "D"],
                      },
                      {
                        id: 2,
                        url: "product-list",
                        text: "Productos",
                        actions: ["U", "D"],
                      },
                      {
                        id: 3,
                        url: "dispens-list",
                        text: "Dispensaciones",
                        actions: ["U"],
                      },
                      {
                        id: 4,
                        url: "receptions-list",
                        text: "Recepciones",
                        actions: ["U", "AR"],
                      },
                      {
                        id: 5,
                        url: "programming-quimio",
                        text: "Programación De Procesos",
                        actions: [],
                      },
                    ],
                  },
                ],
              },
              {
                name: "Jefe De Calidad",
                module: "SF",
                rols: [
                  {
                    id: 1,
                    icon: "ifa fa fa-user",
                    item: "Programación de Pacientes",
                    items: [
                      {
                        id: 2,
                        url: "query-schedule",
                        text: "Consultar Agenda",
                        actions: [],
                      },
                    ],
                  },
                  {
                    id: 2,
                    icon: "ifa fa fa-list-alt",
                    item: "Listados",
                    items: [
                      {
                        id: 0,
                        url: "patient-list",
                        text: "Pacientes",
                        actions: [],
                      },
                      {
                        id: 2,
                        url: "product-list",
                        text: "Productos",
                        actions: [],
                      },
                      {
                        id: 3,
                        url: "dispens-list",
                        text: "Dispensaciones",
                        actions: [],
                      },
                      {
                        id: 5,
                        url: "programming-quimio",
                        text: "Programación De Procesos",
                        actions: [],
                      },
                    ],
                  },
                ],
              },
              {
                name: "Jefe De Quimioterapia",
                module: "SF",
                rols: [
                  {
                    id: 1,
                    icon: "ifa fa fa-user",
                    item: "Programación de Pacientes",
                    items: [
                      {
                        id: 0,
                        url: "patient",
                        text: "Creación",
                      },
                      {
                        id: 1,
                        url: "patient-programing",
                        text: "Programación",
                      },
                      {
                        id: 2,
                        url: "query-schedule",
                        text: "Consultar Agenda",
                        action: ["U", "D"],
                      },
                    ],
                  },
                  {
                    id: 2,
                    icon: "ifa fa fa-list-alt",
                    item: "Listados",
                    items: [
                      {
                        id: 0,
                        url: "patient-list",
                        text: "Pacientes",
                        actions: ["U", "D"],
                      },
                      {
                        id: 2,
                        url: "product-list",
                        text: "Productos",
                        actions: [],
                      },
                      {
                        id: 3,
                        url: "dispens-list",
                        text: "Dispensaciones",
                        actions: ["U"],
                      },
                      {
                        id: 4,
                        url: "receptions-list",
                        text: "Recepciones",
                        actions: [],
                      },
                      {
                        id: 5,
                        url: "programming-quimio",
                        text: "Programación De Procesos",
                        actions: [],
                      },
                    ],
                  },
                ],
              },
              {
                name: "Auxiliar Administrativo",
                module: "SF",
                rols: [
                  {
                    id: 1,
                    icon: "ifa fa fa-user",
                    item: "Programación de Pacientes",
                    items: [
                      {
                        id: 0,
                        url: "patient",
                        text: "Creación",
                      },
                      {
                        id: 1,
                        url: "patient-programing",
                        text: "Programación",
                      },
                      {
                        id: 2,
                        url: "query-schedule",
                        text: "Consultar Agenda",
                        actions: ["U", "D"],
                      },
                    ],
                  },
                  {
                    id: 2,
                    icon: "ifa fa fa-list-alt",
                    item: "Listados",
                    items: [
                      {
                        id: 0,
                        url: "patient-list",
                        text: "Pacientes",
                        actions: ["U", "D"],
                      },
                      {
                        id: 2,
                        url: "product-list",
                        text: "Productos",
                        actions: [],
                      },
                      {
                        id: 3,
                        url: "dispens-list",
                        text: "Dispensaciones",
                        actions: [],
                      },
                      {
                        id: 4,
                        url: "receptions-list",
                        text: "Recepciones",
                        actions: [],
                      },
                      {
                        id: 5,
                        url: "programming-quimio",
                        text: "Programación De Procesos",
                        actions: [],
                      },
                    ],
                  },
                ],
              },
              {
                name: "Asistente De Producción",
                module: "SF",
                rols: [
                  {
                    id: 0,
                    icon: "ifa fa fa-th",
                    item: "Farmacia",
                    items: [
                      {
                        id: 0,
                        url: "reception",
                        text: "Recepción",
                      },
                      {
                        id: 1,
                        url: "dispensation",
                        text: "Dispensación",
                      },
                    ],
                  },
                  {
                    id: 1,
                    icon: "ifa fa fa-user",
                    item: "Programación de Pacientes",
                    items: [
                      {
                        id: 2,
                        url: "query-schedule",
                        text: "Consultar Agenda",
                      },
                    ],
                  },
                  {
                    id: 2,
                    icon: "ifa fa fa-list-alt",
                    item: "Listados",
                    items: [
                      {
                        id: 0,
                        url: "patient-list",
                        text: "Pacientes",
                        actions: [],
                      },
                      {
                        id: 1,
                        url: "thirds-list",
                        text: "Terceros",
                        actions: [],
                      },
                      {
                        id: 2,
                        url: "product-list",
                        text: "Productos",
                        actions: ["U", "D"],
                      },
                      {
                        id: 3,
                        url: "dispens-list",
                        text: "Dispensaciones",
                        actions: ["U"],
                      },
                      {
                        id: 4,
                        url: "receptions-list",
                        text: "Recepciones",
                        actions: ["U", "RR"],
                      },
                      {
                        id: 5,
                        url: "programming-quimio",
                        text: "Programación De Procesos",
                        actions: [],
                      },
                    ],
                  },
                ],
              },
              {
                name: "Jefe De Producción",
                module: "SF",
                rols: [
                  {
                    id: 0,
                    icon: "ifa fa fa-th",
                    item: "Farmacia",
                    items: [
                      {
                        id: 0,
                        url: "reception",
                        text: "Recepción",
                      },
                      {
                        id: 1,
                        url: "dispensation",
                        text: "Dispensación",
                      },
                    ],
                  },
                  {
                    id: 1,
                    icon: "ifa fa fa-user",
                    item: "Programación de Pacientes",
                    items: [
                      {
                        id: 2,
                        url: "query-schedule",
                        text: "Consultar Agenda",
                      },
                    ],
                  },
                  {
                    id: 2,
                    icon: "ifa fa fa-list-alt",
                    item: "Listados",
                    items: [
                      {
                        id: 0,
                        url: "patient-list",
                        text: "Pacientes",
                        actions: [],
                      },
                      {
                        id: 1,
                        url: "thirds-list",
                        text: "Terceros",
                        actions: ["C", "U", "D"],
                      },
                      {
                        id: 2,
                        url: "product-list",
                        text: "Productos",
                        actions: ["U", "D"],
                      },
                      {
                        id: 3,
                        url: "dispens-list",
                        text: "Dispensaciones",
                        actions: ["U"],
                      },
                      {
                        id: 4,
                        url: "receptions-list",
                        text: "Recepciones",
                        actions: ["U", "AR", "RR"],
                      },
                      {
                        id: 5,
                        url: "programming-quimio",
                        text: "Programación De Procesos",
                        actions: [],
                      },
                    ],
                  },
                ],
              },
              {
                name: "Admin",
                module: "SF",
                rols: [
                  {
                    id: 0,
                    icon: "",
                    item: "acciones",
                    items: [
                      {
                        id: 0,
                        url: "reception",
                        text: "radicar",
                        actions: [],
                      },
                      {
                        id: 0,
                        url: "reception",
                        text: "archivar",
                        actions: [],
                      },
                      {
                        id: 0,
                        url: "reception",
                        text: "configurar",
                        actions: [],
                      },
                    ],
                  },
                  {
                    id: 1,
                    icon: "",
                    item: "solicitudes",
                    items: [
                      {
                        id: 1,
                        url: "reception",
                        text: "solicitar",
                        actions: [],
                      },
                      {
                        id: 1,
                        url: "reception",
                        text: "aprobar",
                        actions: [],
                      },
                    ],
                  },
                  {
                    id: 2,
                    icon: "",
                    item: "historial",
                    items: [
                      {
                        id: 2,
                        url: "reception",
                        text: "archivados",
                        actions: [],
                      },
                    ],
                  },
                  {
                    id: 3,
                    icon: "",
                    item: "visualizar",
                    items: [
                      {
                        id: 3,
                        url: "reception",
                        text: "leer",
                        actions: [],
                      },
                      {
                        id: 3,
                        url: "reception",
                        text: "imprimir",
                        actions: [],
                      },
                    ],
                  },
                  {
                    id: 0,
                    icon: "ifa fa fa-th",
                    item: "Farmacia",
                    items: [
                      {
                        id: 0,
                        url: "reception",
                        text: "Recepción",
                        actions: [],
                      },
                      {
                        id: 1,
                        url: "dispensation",
                        text: "Dispensación",
                        actions: [],
                      },
                    ],
                  },
                  {
                    id: 1,
                    icon: "ifa fa fa-user",
                    item: "Programación de Pacientes",
                    items: [
                      {
                        id: 0,
                        url: "patient",
                        text: "Creación",
                        actions: [],
                      },
                      {
                        id: 1,
                        url: "patient-programing",
                        text: "Programación",
                        actions: [],
                      },
                      {
                        id: 2,
                        url: "query-schedule",
                        text: "Consultar Agenda",
                        actions: ["U", "D"],
                      },
                    ],
                  },
                  {
                    id: 2,
                    icon: "ifa fa fa-list-alt",
                    item: "Listados",
                    items: [
                      {
                        id: 0,
                        url: "patient-list",
                        text: "Pacientes",
                        actions: ["U", "D"],
                      },
                      {
                        id: 1,
                        url: "thirds-list",
                        text: "Terceros",
                        actions: ["U", "D", "C"],
                      },
                      {
                        id: 2,
                        url: "product-list",
                        text: "Productos",
                        actions: ["U", "D"],
                      },
                      {
                        id: 3,
                        url: "dispens-list",
                        text: "Dispensaciones",
                        actions: [],
                      },
                      {
                        id: 4,
                        url: "receptions-list",
                        text: "Recepciones",
                        actions: ["U", "AR", "RR"],
                      },
                      {
                        id: 5,
                        url: "programming-quimio",
                        text: "Programación De Procesos",
                        actions: [],
                      },
                    ],
                  },
                ],
              },
              {
                name: "Administrador",
                module: "GD",
                rols: [
                  {
                    id: 0,
                    icon: "",
                    item: "acciones",
                    items: [
                      {
                        id: 0,
                        url: "reception",
                        text: "Radicar",
                        actions: [],
                      },
                      {
                        id: 0,
                        url: "reception",
                        text: "Archivar",
                        actions: [],
                      },
                      {
                        id: 0,
                        url: "reception",
                        text: "configurar",
                        actions: [],
                      },
                    ],
                  },
                  {
                    id: 1,
                    icon: "",
                    item: "solicitudes",
                    items: [
                      {
                        id: 1,
                        url: "reception",
                        text: "solicitar",
                        actions: [],
                      },
                      {
                        id: 1,
                        url: "reception",
                        text: "aprobar",
                        actions: [],
                      },
                    ],
                  },
                  {
                    id: 2,
                    icon: "",
                    item: "historial",
                    items: [
                      {
                        id: 2,
                        url: "reception",
                        text: "archivados",
                        actions: [],
                      },
                    ],
                  },
                  {
                    id: 3,
                    icon: "",
                    item: "visualización",
                    items: [
                      {
                        id: 3,
                        url: "reception",
                        text: "leer",
                        actions: [],
                      },
                      {
                        id: 3,
                        url: "reception",
                        text: "imprimir",
                        actions: [],
                      },
                    ],
                  },
                ],
              },
            ],
            legalRepresentative: "",
            address: {
              country: "",
              town: "",
              addInfo: "",
            },
          },
          birthdate: "",
          phone: "",
          photo: "",
          address: {
            town: "",
            adInfo: "",
          },
          mail: "",
          accessData: {
            nickname: "",
            password: "",
            token: "ashg23hgqh2g1hg123g",
          },
          license: "",
          payment: {
            frecuency: "monthly",
            amount: "20000",
          },
        },
      },
    };
  },

  methods: {
    async createLicense() {
      let data = {
        expirationDate: "2030-03-19 19:28:01.310Z",
        details: [
          {
            module: "SF",
            items: ["0", "1", "2"],
          },
          {
            module: "GD",
            items: ["0", "1", "2", "3", "4", "5", "6"],
          },
        ],
      };
      await axios
        .post("https://ionicogd.ionico.com.co/user/create/license", data)
        .then(async (response) => {
          this.admin.data.license = response.data.data;
        })
        .catch(async (error) => {
          console.log("error", error);
        });
    },
    async handleSubmit() {
      this.submitted = true;
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          await this.uploadRut();
          return;
        }
        console.log("Correct them errors!");
      });
    },
    async createAdmin() {
      this.$swal({
        title: "Por favor, confime su información",
        html: `
          <div class="row">
            <h2>Información personal</h2>
              <p class="fw-bold">Nombre: <span class="fw-lighter">${this.admin.data.name.first}</span></p>
              <p class="fw-bold">Apellido: <span class="fw-lighter">${this.admin.data.name.last}</span></p>
              <p class="fw-bold">Tipo de cedula: <span class="fw-lighter">${this.admin.data.dni.dType}</span></p>
              <p class="fw-bold">Cedula: <span class="fw-lighter">${this.admin.data.dni.dni}</span></p>
              <p class="fw-bold">Fecha de nacimiento: <span class="fw-lighter">${this.admin.data.birthdate}</span></p>
              <p class="fw-bold">Telefono: <span class="fw-lighter">${this.admin.data.phone}</span></p>
              <p class="fw-bold">Departamento: <span class="fw-lighter">${this.userDepartment}</span></p>
              <p class="fw-bold">Ciudad: <span class="fw-lighter">${this.admin.data.address.town}</span></p>
              <p class="fw-bold">Correo: <span class="fw-lighter">${this.admin.data.mail}</span></p>
              <p class="fw-bold">Nickname: <span class="fw-lighter">${this.admin.data.accessData.nickname}</span></p>
              <p class="fw-bold">Contraseña: <span class="fw-lighter">${this.admin.data.accessData.password}</span></p>
              <h3>Información de la Empresa</h3>
              <p class="fw-bold">Nombre de la empresa: <span class="fw-lighter">${this.admin.data.company.name}</span></p>
              <p class="fw-bold">Email de la empresa: <span class="fw-lighter">${this.admin.data.company.email}</span></p>
              <p class="fw-bold">NIT: <span class="fw-lighter">${this.admin.data.company.nit}</span></p>
              <p class="fw-bold">Representante legal: <span class="fw-lighter">${this.admin.data.company.legalRepresentative}</span></p>
              <p class="fw-bold">Departamento: <span class="fw-lighter">${this.admin.data.company.address.country}</span></p>
              <p class="fw-bold">Ciudad: <span class="fw-lighter">${this.admin.data.company.address.town}</span></p>
              <p class="fw-bold">Dirección: <span class="fw-lighter">${this.admin.data.company.address.addInfo}</span></p>
              <p class="fw-bold">Actividad Economica: <span class="fw-lighter">${this.admin.data.company.economyAtivity}</span></p>

            <h3>Datos de la empresa</h3>
              
          </div>
        `,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, enviar!",
        cancelButtonText: "Cancelar",
        confirmButtonClass: "btn btn-success",
        cancelButtonClass: "btn btn-danger",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          axios
            .post(
              "https://ionicogd.ionico.com.co/user/create/admin",
              this.admin
            )
            .then(async (response) => {
              this.$swal({
                title: "Solicitud enviada",
                text: "Te contactaremos muy pronto",
                type: "success",
                confirmButtonText: "Cerrar",
                confirmButtonClass: "btn btn-success",
                buttonsStyling: false,
                reverseButtons: true,
              }).then((result) => {
                if (result.value) {
                  console.log(response);
                  this.$emit("close");
                }
              });
            })
            .catch(async (error) => {
              this.$swal({
                title:
                  "No se pudo enviar la solicitud, por favor intentalo de nuevo mas tarde",
                text: error.message,
                type: "error",
                confirmButtonText: "Cerrar",
                confirmButtonClass: "btn btn-success",
                buttonsStyling: false,
                reverseButtons: true,
              });
            });
            this.resetForm()
        }
      });
    },
    async getCiudades() {
      await axios
        .get(
          "https://www.datos.gov.co/resource/xdk5-pm3f.json?departamento=" +
            this.userDepartment
        )
        .then(async (response) => {
          let ciudades = response.data;
          this.ciudades = ciudades;
        })
        .catch(async (error) => {
          console.log("error", error);
        });
    },
    async getCiudadesEmpresa() {
      await axios
        .get(
          "https://www.datos.gov.co/resource/xdk5-pm3f.json?departamento=" +
            this.admin.data.company.address.country
        )
        .then(async (response) => {
          let ciudadesEmpresa = response.data;
          this.ciudadesEmpresa = ciudadesEmpresa;
        })
        .catch(async (error) => {
          console.log("error", error);
        });
    },
    togglePassword() {
      let show = document.getElementById("password");
      if (this.showpassword == false) {
        this.showpassword = true;
        show.type = "text";
      } else {
        this.showpassword = false;
        show.type = "password";
      }
    },
    handleFileUpload(event) {
      this.nameFile = event.target.files[0].name;
      this.files = event.target.files[0];
    },
    async uploadRut() {
      let formData = new FormData();
      formData.append("pdf", this.files);
      await axios
        .post("https://ionicogd.ionico.com.co/user/upload/admin/rut", formData, {
          processData: false,
          // contentType: 'multipart/form-data',
          headers: {
            Accept: "application/json",
            "Content-Type": `multipart/form-data boundary=${
              formData._boundary
            }`,
            "x-m-i": "GD",
          },
          onUploadProgress: function(progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            );
          }.bind(this),
        })
        .then(async (response) => {
          this.admin.data.company.rut = this.$myCryptorts.decode(
            response.data.data
          );
          await this.createAdmin();
        })
        .catch(() => {
          this.$swal({
            title: "Error!",
            message: "Error al guardar documento",
            type: "error",
          });
        });
    },
    resetForm() {
      this.admin.data.name.first=''
      this.admin.data.name.last=''
      this.admin.data.dni.dType=''
      this.admin.data.dni.dni=''
      this.admin.data.birthdate=''
      this.admin.data.phone=''
      this.userDepartment=''
      this.admin.data.address.town=""
      this.admin.data.mail=''
      this.admin.data.accessData.nickname=''
      this.admin.data.accessData.password=''
      this.confirmPassword=''
      this.admin.data.company.name=''
      this.admin.data.company.email=''
      this.admin.data.company.nit=''
      this.admin.data.company.legalRepresentative=''
      this.admin.data.company.address.country=''
      this.admin.data.company.address.town=''
      this.admin.data.company.address.addInfo=''
      this.admin.data.company.economyAtivity=''
    },
  },
  async created() {
    this.departamentosEmpresa = departamentos;
    this.departamentos = departamentos;
    this.createLicense();
  },
};
</script>

<style lang="scss">

.input-file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  + .js-labelFile {
    overflow: hidden;
    white-space: nowrap;
    padding: 0 10px;
    cursor: pointer;
    .icon:before {
      //font-awesome
      content: "\f093";
    }
    &.has-file {
      .icon:before {
        //font-awesome
        content: "\f00c";
        color: #5aac7b;
      }
    }
  }
}
</style>