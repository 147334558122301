<template>
    <section class="pt-0">
      <div class="container">
        <div class="row align-items-center justify-content-between">
          <div class="col-12 col-lg-6 mb-8 mb-lg-0">
            <img src="images/about/07.png" alt="Image" class="img-fluid">
          </div>
          <div class="col-12 col-lg-6 col-xl-5">
            <div class="accordion" id="accordion">
              <div class="accordion-item mb-4">
                <h2 class="accordion-header" id="headingOne">
                  <button class="accordion-button border mb-0 bg-transparent rounded text-dark collapsed" type="button"
                    data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true"
                    aria-controls="collapseOne">
                    GESTIÓN DOCUMENTAL
                  </button>
                </h2>
                <div id="collapseOne"
                  class="accordion-collapse border-0 collapse showaccordion-collapse border-0 collapse"
                  aria-labelledby="headingOne" data-bs-parent="#accordion">
                  <div class="accordion-body text-muted">Radicación de documentos, revisión de documentos, devolución de
                    documentos, radicación de solicitudes…. clave de acceso por usuario...<br><br>


                    • Solicitudes<br>
                    • Documentos pendientes por revisión<br>
                    • Documentos devueltos<br>
                    • Documentos archivados<br>
                    • Publicación<br>
                    • Divulgación de documentos<br>
                    • Hacer consulta sobre el contenido de un documento<br>
                    • Acciones correctivas, preventivas o de mejora<br><br>



                    • Ionico proporciona un índice con los documentos organizados por proceso.<br>

                    • Ionico de forma automática le emite un código al documento según el tipo de documento y el proceso
                    al que pertenece.<br>

                    • El usuario puede descargar de forma fácil los formatos que están relacionados con el documento.
                    <br>

                    • El buscador del documento permite buscar por palabra clave, nombre del documento, código del
                    documento, proceso o responsable.<br>

                    • El usuario podrá ver que documentos han enviado como devueltos para su modificación a la persona
                    que lo esta creando.<br>

                    • Los usuarios lideres de los procesos pueden ver todos tos los documentos archivados, pero no poden
                    modificarlos o descargarlos.<br>

                    • El usuario podrá realizar consultas sobre el contenido que no tenga claro en un documento.<br>

                    • Los documentos se actualizarán de versión de forma automática, la versión anterior pasará al
                    archivo.<br>

                    • Barra de fácil manejo para subir y bajar en el documento.<br>

                    • Historial de movimiento que ha tenido el documento desde su creación.<br>

                    • Solicitud de códigos de documentos<br><br>



                    Segregación documental:<br>

                    • Nombre del documento<br>
                    • Código<br>
                    • Versión<br>
                    • Proceso<br>
                    • Tipo de documento<br>
                    • Elaborado por<br>
                    • Revisado por<br>
                    • Aprobado por<br>
                    • Estatus (radicado, revisado, aprobado, devuelto)
                  </div>
                </div>
              </div>
              <div class="accordion-item mb-4">
                <h2 class="accordion-header" id="headingTwo">
                  <button class="accordion-button border mb-0 bg-transparent rounded text-dark" type="button"
                    data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false"
                    aria-controls="collapseTwo">
                    GESTIÓN DE SOLICITUDES
                  </button>
                </h2>
                <div id="collapseTwo"
                  class="accordion-collapse border-0 collapse showaccordion-collapse border-0 collapse  show"
                  aria-labelledby="headingTwo" data-bs-parent="#accordion">
                  <div class="accordion-body text-muted">Las solicitudes podrán ser radicadas de forma externa o interna
                    por cualquier persona.<br><br>

                    • El usuario responsable de las solicitudes puede realizar los seguimientos en tiempo según sea
                    asignado.<br>

                    • El usuario podrá responder de forma directa al solicitante<br><br>

                    Las solicitudes estarán divididas en:<br>

                    • Quejas<br>
                    • Reclamos <br>
                    • Felicitaciones<br>
                    • Eventos adversos o reacciones adversas<br>
                    • Acciones correctivas o preventivas <br>
                    • Observaciones o acciones de mejora



                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                  <button class="accordion-button border mb-0 bg-transparent rounded text-dark collapsed" type="button"
                    data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false"
                    aria-controls="collapseThree">
                    GESTIÓN DE PROYECTOS
                  </button>
                </h2>
                <div id="collapseThree" class="accordion-collapse border-0 collapse" aria-labelledby="headingThree"
                  data-bs-parent="#accordion">
                  <div class="accordion-body text-muted">Los proyectos en curso están incluidos en el espacio de trabajo
                    de cada persona y se cómprate en grupo, donde los participantes pueden editar e ir escribiendo el
                    estado en que se encuentra cada tarea del proyecto.<br>

                    <br>
                    • Ionico cuenta con un espacio donde el usuario puede crear los diferentes proyectos en curso.<br>

                    • Espacio de trabajo personal, solo tendrá acceso completo cada persona.<br>

                    • Personalización del espacio de trabajo: un color a cada proyecto en curso.<br>

                    • Proyectos con uno o varios objetivos y metas específicos con fechas de cumplimiento.<br>

                    • Ionico avisa al usuario por medio de correo electrónico en que estado se encuentra cada tarea,
                    meta u objetivo con previa fecha asignada.<br>

                    • Avisos y notificaciones totalmente programables<br>

                    • Personalización del estatus del proyecto por parte del usuario administrador de cada proyecto<br>

                    • Arrastre los tableros y casillas de los proyectos en curso para cambiarlos de estatus o de orden
                    de prioridad.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
export default {
    name:'faqComponent'
}
</script>