<template>
    <section>
      <div class="container">
        <div class="row align-items-center">
          <div class="col-12 col-md-12 col-lg-4 order-lg-1 mb-8 mb-lg-0">
            <div class="mb-0"> <span class="badge badge-primary-soft p-2">
                <i class="la la-money ic-3x rotation"></i>
              </span>
              <h2 class="mt-3">Herramienta completa que permite administrar y gestionar</h2>
              <p class="lead mb-0">Nuestra aplicación empresarial se basa en un sistema estructural funcional de alta
                tecnología </p>
            </div>
          </div>
          <div class="col-12 col-md-12 col-lg-8">
            <div class="row align-items-center">
              <div class="col-12 col-md-6 mb-8 mb-md-0">
                <!-- Card -->
                <div class="card border-0 shadow">
                  <!-- Body -->
                  <div id="planes" class="card-body py-8 px-6">
                    <!-- Badge -->
                    <div class="text-center mb-5"> <span class="badge text-dark shadow">
                        <span class="h6 text-uppercase">Plan Básico</span>
                      </span>
                    </div>
                    <div class="mb-5">
                      <img class="img-fluid" src="images/svg/01.svg" alt="">
                    </div>
                    <!-- Features -->
                    <div class="d-flex align-items-start justify-content-between">
                      <!-- Text -->
                      <p>Programación</p>
                      <!-- Check -->
                      <div class="ms-4"> <i class="la la-check text-primary fw-bold"></i>
                      </div>
                    </div>
                    <div class="d-flex align-items-start justify-content-between">
                      <!-- Text -->
                      <p>Ejecución</p>
                      <!-- Check -->
                      <div class="ms-4"> <i class="la la-check text-primary fw-bold"></i>
                      </div>
                    </div>
                    <div class="d-flex align-items-start justify-content-between">
                      <!-- Text -->
                      <p>Herramientas</p>
                      <!-- Check -->
                      <div class="ms-4"> <i class="la la-check text-primary fw-bold"></i>
                      </div>
                    </div>
                    <div class="d-flex align-items-start justify-content-between">
                      <!-- Text -->
                      <p>Implementación ($3.500.000)</p>
                      <!-- Check -->
                      <div class="ms-4"> <i class="la la-check text-primary fw-bold"></i>
                      </div>
                    </div>
                    <!-- Price -->
                    <div class="d-flex justify-content-center mt-5"> <span class="h3 mb-0 mt-2">$</span>
                      <span class="price display-3 text-primary font-w-6">600.000</span>
                    </div>
                    <!-- Text -->
                    <p class="text-center text-muted">Mensuales</p>
                    <!-- Button --> 
                    <FormPlanBasicoModal texto= "Adquiere tu plan" size="col-12" titulo="Adquir plan" classes="btn btn-block btn-primary mt-5" /> 
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <!-- Card -->
                <div class="card border-0 shadow">
                  <!-- Body -->
                  <div class="card-body py-8 px-6">
                    <!-- Badge -->
                    <div class="text-center mb-5"> <span class="badge text-dark shadow">
                        <span class="h6 text-uppercase">Plan Profesional</span>
                      </span>
                    </div>
                    <div class="mb-5">
                      <img class="img-fluid" src="images/svg/02.svg" alt="">
                    </div>
                    <!-- Features -->
                    <div class="d-flex align-items-start justify-content-between">
                      <!-- Text -->
                      <p>Programación</p>
                      <!-- Check -->
                      <div class="ms-4"> <i class="la la-check text-primary fw-bold"></i>
                      </div>
                    </div>
                    <div class="d-flex align-items-start justify-content-between">
                      <!-- Text -->
                      <p>Ejecución</p>
                      <!-- Check -->
                      <div class="ms-4"> <i class="la la-check text-primary fw-bold"></i>
                      </div>
                    </div>
                    <div class="d-flex align-items-start justify-content-between">
                      <!-- Text -->
                      <p>Herramientas</p>
                      <!-- Check -->
                      <div class="ms-4"> <i class="la la-check text-primary fw-bold"></i>
                      </div>
                    </div>
                    <div class="d-flex align-items-start justify-content-between">
                      <!-- Text -->
                      <p>Implementación ($3.500.000)</p>
                      <!-- Check -->
                      <div class="ms-4"> <i class="la la-check text-primary fw-bold"></i>
                      </div>
                    </div>
                    <!-- Price -->
                    <div class="d-flex justify-content-center mt-5"> <span class="h3 mb-0 mt-2">$</span>
                      <span class="price display-3 text-primary font-w-6">1.000.000</span>
                    </div>
                    <!-- Text -->
                    <p class="text-center text-muted">Mensual</p>
                    <!-- Button --> 
                    <FormPlanProfesionalModal  texto= "Adquiere tu plan" size="col-12" titulo="Adquir plan" classes="btn btn-block btn-primary mt-5"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .container -->
    </section>
</template>

<script>
import FormPlanBasicoModal from '../formModal/formPlanBasicoModal.vue';
import FormPlanProfesionalModal from '../formModal/formPlanProfesionalModal.vue';


export default {
    name: "PricingComponent",
    components: { FormPlanBasicoModal, FormPlanProfesionalModal },
}

</script>
